/*@@@@@@@@@@@@@@   Shop Cart Page Start Here  @@@@@@@@@@@@@@@@@*/
.shop-cart {
	.section-wrapper {
		.cart-top {
			margin-bottom: 40px;
			box-shadow: 0 0 10px rgba(136, 136, 136, .10);
			background: $primary-color;

			@include breakpoint(max-md) {
				// overflow-x: auto;
			}

			table {
				font-weight: 700;
				@extend %w-100;

				@include breakpoint(max-md) {
					// width: 720px;
				}

				.cat-product {
					.p-content {
						@include breakpoint(max-md) {
							display: none;
						}
					}
				}

				.cat-quantity {
					@include breakpoint(max-md) {
						.cart-plus-minus {
							width: 96px;

							.cart-plus-minus-box {
								width: 96px;
							}

							.qtybutton {
								width: 30px;
							}
						}
					}
				}

				.cat-price {
					@include breakpoint(max-md) {
						display: none;
					}
				}

				thead {
					tr {
						@extend %bg-theme;

						th {
							color: $white-color;
							padding: 18px;
							border: none;
							text-transform: uppercase;

							@include breakpoint(max-sm) {
								font-size: 14px;
								padding: 10px;
							}

							&:nth-child(3) {
								@extend %text-center;
							}

							&:last-child {
								text-align: right;
							}
						}
					}
				}

				tbody {
					tr {
						border-bottom: 1px solid rgba($white-color, $alpha: .1);
						border-right: none;
						border-left: none;
						border-top: none;

						&:last-child {
							border-bottom: none;
						}

						td {
							padding: 20px 10px;
							border: none;

							@include breakpoint(md) {
								padding: 20px;
							}

							&:last-child {
								text-align: right;
							}

							&.product-item {
								align-items: center;
								@extend %flex;

								.p-thumb {
									width: 50px;
									margin: auto;

									@include breakpoint(md) {
										width: 85px;
									}
								}

								.p-content {
									width: calc(100% - 85px);
									padding-left: 10px;

									@include breakpoint(max-md) {
										display: none;
									}

									a {
										@extend %white-color;
										@include add-prefix(transition, $transition);
										text-transform: uppercase;
										font-family: $text-font;

										&:hover {
											@extend %theme-color;
										}
									}
								}
							}

							&.cat-toprice,
							&.cat-edit {
								@include breakpoint(max-md) {
									text-align: center;
									font-size: 14px;
								}
							}

							input {
								// background: $body-color;
								color: $white-color;
							}
						}
					}
				}
			}
		}

		.cart-bottom {
			@extend %mb-15;
			box-shadow: 0 0 10px rgba(136, 136, 136, .10);
			@extend %hidden;
			background: $primary-color;

			.cart-checkout-box {
				padding: 30px;
				@extend %justify-between;
				@extend %align-items-center;
				@extend %flex;

				.coupon {
					max-width: 340px;
					display: flex;

					input {
						padding: 18px;
						@extend %mb-0;
						border: 1px solid rgba($white-color, $alpha: .1);
						border-radius: 3px 0 0 3px;
						// background-color: $body-color;
						color: $white-color
					}

					input[type="submit"] {
						font-weight: 500;
						margin-left: -10px;
						cursor: pointer;
						@extend %bg-theme;
						border-radius: 0 3px 3px 0;
					}
				}

				.cart-checkout {
					@extend %w-100;
					margin-top: 30px;
					@extend %justify-between;
					@extend %flex;
					gap: 10px;

					@include breakpoint(lg) {
						max-width: 450px;
						text-align: right;
						margin-top: 0;
					}

					input {
						background-color: $body-color;
						color: $white-color;
						@extend %black-color;
						border-radius: 3px;
					}

					input[type="submit"] {
						padding: 10px 30px;
						border: 1px solid rgba($white-color, $alpha: .1);
						width: auto;
						cursor: pointer;
						@include add-prefix(transition, $transition);

						@include breakpoint(max-sm) {
							width: 100%;
							margin-bottom: 15px;

							&:last-child {
								margin-bottom: 0;
							}
						}

						&:hover {
							@extend %theme-color;
							border-color: $theme-color;
						}
					}
				}

			}

			.shiping-box {
				padding: 30px 20px;
				border-top: 1px solid rgba($white-color, $alpha: .1);

				.calculate-shiping {
					@include breakpoint(max-md) {
						margin-bottom: 30px;
					}

					h3 {
						@extend %mb-30;
					}

					input {
						background-color: $primary-color;
						color: $white-color;
					}

					.outline-select {
						margin-bottom: 20px;
						position: relative;
						background-color: $primary-color;
						color: $white-color;

						select {
							@extend %w-100;
							padding: 18px 20px;
							background: $primary-color;
							-webkit-appearance: none;
							-moz-appearance: none;
							border: 1px solid rgba($white-color, $alpha: .1);
							position: relative;
							outline: none;
							border-radius: 3px;
							color: $white-color;
							cursor: pointer;
						}

						&.shipping-select {
							@extend %w-100;

							@include breakpoint(lg) {
								width: 50%;
								float: left;
							}
						}

						.select-icon {
							top: 1px;
							right: 1px;
							padding: 15px;
							color: $white-color;
							border-radius: 0px 3px 3px 0px;
							@extend %abs;
							background-color: $primary-color;
							border-radius: 0 3px 3px 0;
						}
					}

					.cart-page-input-text {
						@extend %w-100;
						float: right;
						padding: 19px 20px;
						font-size: 14px;
						margin-bottom: 22px;
						border: 1px solid rgba($white-color, $alpha: .1);
						border-radius: 3px;

						@include breakpoint(lg) {
							width: 45%;
						}
					}

					button {
						border: none;
						outline: none;
						cursor: pointer;
						border-radius: 3px;
						line-height: 56px;
						@extend %mt-10;
					}
				}

				.cart-overview {
					h3 {
						@extend %mb-30;
					}

					ul {
						@extend %m-0;

						li {
							list-style: none;
							@extend %align-items-center;
							@extend %justify-between;
							border: 1px solid rgba($white-color, $alpha: .1);
							padding: 15px 20px;
							@extend %mb-15;
							background-color: $primary-color;
							color: $white-color;
							@extend %flex;
							border-radius: 3px;

							span {
								color: $white-color;
							}

							.pull-right {
								@extend %mb-0;
								// color: $theme-color;
							}
						}
					}
				}
			}
		}
	}
}