.testimonial-item {
    .testimonial-inner {
        background: $primary-color;
        @extend %p-30;
        @include add-prefix(transition, $transition);
        border-radius: 6px;

        &:hover {
            @include add-prefix(transform, translateY(-5px));

            .testimonial-head {
                a {
                    img {
                        @include add-prefix(transform, rotateY(360deg));
                    }
                }
            }
        }

        .testimonial-head {
            @extend %flex;
            @extend %pb-20;
            @extend %mb-20;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba($border-color, $alpha: .1);

            .testi-top {
                @extend %flex;
            }

            .testimonial-thumb {
                width: 60px;
                height: 60px;
                border-radius: 100%;
                @extend %hidden;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .name-des {
                @extend %pl-20;

                p {
                    @extend %mp-0;
                    @include font-size(18px);
                }
            }

            a {
                img {
                    @include add-prefix(transition, $transition);
                }
            }
        }

        .testimonial-body {
            p {}
        }

        .testimonial-footer {
            @extend %flex;
            @extend %justify-start;
            @extend %align-items-center;

            ul {
                @extend %flex;
                @extend %mp-0;

                li {
                    padding: 5px 10px;
                    background: #00b67a;
                    @extend %mr-5;
                    border-radius: 3px;

                    i {
                        color: $white-color;
                    }
                }
            }

            h5 {
                @extend %mb-0;
                @extend %ml-10;
                color: #00b67a;
            }
        }
    }
}


.swiper-slide.swiper-slide-next {
    .testimonial-item {
        .testimonial-inner {
            .testimonial-footer {
                ul {
                    li {
                        background: #f06a22;
                        border-radius: 100%;
                    }
                }

                h5 {
                    color: #f06a22;
                }
            }
        }
    }
}