//*----------------------------
//** Game collection item
//*----------------------------

.game-item {
    // background-image: url(../images/game/bg1.svg);
    background-size: cover;
    background-position: center;
    height: 250px;
    transition: $transition;



    .game-item-inner {
        @extend %rel;
        @extend %flex;
        @extend %align-items-center;
        @extend %justify-center;
        @extend %h-100;
        border-radius: 3px;
        background: $primary-color;


        // &.bg-1 {
        //     background: rgb(74, 0, 154);
        //     background: radial-gradient(circle, rgba(74, 0, 154, 1) 0%, rgba(8, 2, 35, 1) 100%);
        // }

        // &.bg-2 {
        //     background: rgb(191, 185, 100);
        //     background: radial-gradient(circle, rgba(191, 185, 100, 1) 0%, rgba(41, 49, 14, 1) 100%);
        // }

        // &.bg-3 {
        //     background: rgb(55, 193, 213);
        //     background: radial-gradient(circle, rgba(55, 193, 213, 1) 0%, rgba(8, 14, 55, 1) 100%);
        // }


        // &.bg-4 {
        //     background: rgb(223, 73, 16);
        //     background: radial-gradient(circle, rgba(223, 73, 16, 1) 0%, rgba(245, 200, 2, 1) 100%);
        // }

        // &.bg-5 {
        //     background: #6dc79c;
        //     background: radial-gradient(circle, #6dc79b 0%, #003127 100%);
        // }

        // &.bg-6 {
        //     background: #f06b27;
        //     background: radial-gradient(circle, #f06b27 0%, #161717 100%);
        // }

        // &.bg-7 {
        //     background: #29a7ce;
        //     background: radial-gradient(circle, #29a7ce 0%, #161717 100%);
        // }

        // &.bg-8 {
        //     background: #c41f0e;
        //     background: radial-gradient(circle, #c41f0e 0%, #161717 100%);
        // }
        .game-thumb {
            .match-team-list {
                gap: 10px;
                @extend %mb-0;
            }
        }


    }

    .game-overlay {
        @extend %abs;
        visibility: hidden;
        opacity: 0;
        transition: all .45s cubic-bezier(.68, -0.55, .27, 1.55);
        display: grid;
        place-content: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        background-color: rgba($black-color, $alpha: .80)
    }

    &:hover {
        .game-overlay {
            visibility: visible;
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);

        }
    }
}