/*===== keyframes for ripple====*/

/* ripple effect */
@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba($white-color, 0.3),
            0 0 0 4px rgba($white-color, 0.3),
            0 0 0 8px rgba($white-color, 0.3),
    }

    100% {
        box-shadow: 0 0 0 8px rgba($white-color, 0.3),
            0 0 0 12px rgba($white-color, 0.3),
            0 0 0 16px rgba($white-color, 0);
    }
}


@keyframes pluse_animate {
    0% {
        opacity: 1;
        -webkit-transform: translate(-50%, -50%) scale(0.3);
        transform: translate(-50%, -50%) scale(0.3);
    }

    100% {
        opacity: 0;
        -webkit-transform: translate(-50%, -50%) scale(2);
        transform: translate(-50%, -50%) scale(2);
    }
}

//floating keyframes
@keyframes rotating {
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(360deg);
    }
}