/**
 * Basic typography style for copy text
 */
body {
  @include font-size($fs-base);
  color: $white-color;
  font-family: $text-font;
  background-color: $body-color;


  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $white-color;
    font-family: $title-font;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.3;
  }

  h1 {
    @include font-size($fs-h1);
  }

  h2 {
    @include font-size($fs-h2);
  }

  h3 {
    @include font-size($fs-h3);
  }

  h4 {
    @include font-size($fs-h4);
  }

  h5 {
    @include font-size($fs-h5);
  }

  h6 {
    @include font-size($fs-h6);
  }

  a>h1,
  a>h2,
  a>h3,
  a>h4,
  a>h5,
  a>h6 {
    @include add-prefix(transition, $transition);
  }

  a {
    @include add-prefix(transition, $transition);
    display: inline-block;
    text-decoration: none;
    color: $white-color;

    &:hover {
      color: $white-color;
    }

  }

  ul {
    padding: 0;
  }

  input,
  textarea,
  select {
    border: 1px solid rgba($white-color, $alpha: .1);
    background: #76767624;
  }
}