//*----------------------------
//** Section header section css
//*----------------------------

.section-header {
    @extend %text-center;
    margin-bottom: 65px;

    @include breakpoint(lg) {
        margin-bottom: 85px;
    }


    h2 {
        @extend %rel;
        text-transform: uppercase;

        &:after {
            @extend %abs;
            @include add-prefix(transform, translateX(-50%));
            width: 150px;
            height: 25px;
            background-image: url(../images/shape/header.png);
            background-size: contain;
            background-repeat: no-repeat;
            bottom: -35px;
            left: 50%;

            @include breakpoint(lg) {
                width: 250px;
                bottom: -38px;
            }
        }
    }
}

.section-header-2 {
    margin-bottom: 65px;
    gap: 10px;
    @extend %rel;

    &:after {
        @extend %abs,
        %w-100;
        height: 1px;
        background-color: rgba($white-color, $alpha: .1);
        left: 0;
        bottom: -15px;
    }

    @include breakpoint(lg) {
        margin-bottom: 85px;
    }


    h2 {
        @extend %rel;
        text-transform: uppercase;
    }

    .section-header-content {
        ul {
            @extend %mb-0;
        }
    }
}


//*----------------------------
//** Section Paddings
//*----------------------------
.padding-top {
    padding-top: 75px;

    @include breakpoint(lg) {
        padding-top: 115px;
    }
}

@include breakpoint(lg) {
    .padding-lg-top {
        padding-top: 115px;
    }
}

.padding-bottom {
    padding-bottom: 80px;

    @include breakpoint(lg) {
        padding-bottom: 120px;
    }
}

.bg-attachment {
    background-attachment: fixed;
}

.ls-2 {
    letter-spacing: 2px;
}


@include breakpoint(xl) {
    .mr-xl-50-none {
        margin-right: -50px;
    }

    .ml-xl-50-none {
        margin-left: -50px;
    }
}

//*----------------------------
//** Rating star css
//*----------------------------

.rating-star {
    li {
        i {
            color: $theme-color;
        }
    }
}


//*----------------------------
//** Rating star css
//*----------------------------

.theme-color {
    color: $theme-color;
}



// //*----------------------------
// //** shape image css
// //*----------------------------
// .body-shape {

//     &:after {
//         @include breakpoint(md) {
//             position: fixed;
//             content: "";
//             width: 100%;
//             background-image: url(../images/shape/body-shape.png);
//             background-size: contain;
//             background-repeat: no-repeat;
//             top: 50%;
//             height: 500px;
//             z-index: -1;
//         }
//     }
// }


// .cta-content
.cta-item {
    padding: 50px 25px;

    @include breakpoint(lg) {
        padding: 70px 40px;
    }
}




// social-media
.social-media {
    li {
        a {
            width: 40px;
            height: 40px;
            line-height: 40px;
            @extend %mr-5;
            border-radius: 50%;
            @include font-size(20px);
            @extend %text-center;

            &:hover {
                color: $white-color;
                @include add-prefix(transform, translateY(-2px));
            }

            &.vimeo {
                background: $vimeo;
            }

            &.youtube {
                background: $youtube;
            }

            &.twitch {
                background: $twitch;
            }

            &.facebook {
                background: $facebook;
            }

            &.twitter {
                background: $twitter;
            }

            &.linkedin {
                background: $linkedin;
            }

            &.dribbble {
                background: $dribbble;
            }

            &.skype {
                background: $skype;
            }
        }
    }
}

.w-xl-50 {
    @include breakpoint(xl) {
        width: 50% !important;
    }
}

// filter-button-group
.common-filter-button-group {
    @include breakpoint(sm) {
        border-top: 1px solid rgba($white-color, $alpha: .4);
        border-bottom: 1px solid rgba($white-color, $alpha: .4);
    }

    li {
        padding: 10px 20px;
        @extend %pointer;
        @extend %rel;
        border: 1px solid rgba($white-color, $alpha: .3);
        width: 100%;
        @extend %mb-5;
        text-align: center;

        @include breakpoint(sm) {
            border: none;
            width: auto;
            margin: 0;
        }

        &::after {
            @extend %abs;
            display: none;
            @include add-prefix(transform, rotate(20deg));

            @include breakpoint(sm) {
                width: 1px;
                height: calc(100% + 2px);
                background: rgba($white-color, $alpha: .4);
                top: 0;
                right: 0;
                display: block;
            }
        }

        &:first-child {
            &::before {
                @extend %abs;
                display: none;
                @include add-prefix(transform, rotate(20deg));

                @include breakpoint(sm) {
                    width: 1px;
                    height: calc(100% + 2px);
                    background: rgba($white-color, $alpha: .4);
                    top: 0;
                    left: 0;
                    display: block;
                }
            }
        }

        &.is-checked {
            @extend %theme-color;
        }
    }
}


/*@@@@@@@@@@@@@@   cart-plus-minus Start Here  @@@@@@@@@@@@@@@@@*/
.cart-plus-minus {
    border: 1px solid rgba($white-color, $alpha: .1);
    width: 120px;
    margin: 0 auto;
    cursor: pointer;
    @extend %rel;
    @extend %hidden;

    .dec.qtybutton {
        border-right: 1px solid rgba($white-color, $alpha: .1);
        height: 40px;
        left: 0;
        top: 0;
    }

    .cart-plus-minus-box {
        border: medium none;
        @extend %black-color;
        float: left;
        font-size: 14px;
        height: 40px;
        @extend %m-0;
        text-align: center;
        width: 120px;
        outline: none;
        color: $white-color;
    }

    .qtybutton {
        float: inherit;
        font-size: 14px;
        font-weight: 500;
        width: 40px;
        height: 40px;
        line-height: 40px;
        @extend %m-0;
        text-align: center;
        @extend %abs;
        @include add-prefix(transition, $transition);
        color: $white-color;

        &:hover {
            color: $white-color;
            @extend %bg-theme;
        }
    }

    .inc.qtybutton {
        border-left: 1px solid rgba($white-color, $alpha: .1);
        right: 0;
        top: 0;
    }
}


// default-pagination
.default-pagination {
    @extend %flex;
    margin: -7px !important;
    margin-top: 31px !important;
    @extend %justify-center;

    @include breakpoint(md) {
        margin-top: 41px !important;
    }

    li {
        padding: 7px;

        a {
            width: 46px;
            height: 46px;
            line-height: 46px;
            text-align: center;
            border-radius: 5px;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
            background: $white-color;
            color: $black-color;

            &:hover,
            &.active {
                background: $theme-color;
                box-shadow: none;
                color: $white-color;
            }
        }
    }
}


// menu-item-has-children
.catmenu-item-has-children {
    >a {
        @extend %rel;

        &::after {
            @extend %abs;
            top: 50%;
            transform: translateY(-50%);
            right: 25px;
            width: 10px;
            height: 2px;
            background: $white-color;
        }

        &::before {
            @extend %abs;
            top: 50%;
            transform: translateY(-50%);
            right: 29px;
            width: 2px;
            height: 10px;
            background-color: $white-color;
            @include add-prefix(transition, $transition);
        }

        @include breakpoint(lg) {
            &::after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                width: 10px;
                height: 2px;
                background-color: $white-color;
            }

            &::before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 4px;
                width: 2px;
                height: 10px;
                background-color: $white-color;
                @include add-prefix(transition, $transition);
            }
        }
    }

    .menu-item-has-children {
        a {
            @include breakpoint(lg) {
                &::after {
                    right: 11px;
                }

                &::before {
                    right: 15px;
                }
            }

            &:hover {
                @include breakpoint(lg) {

                    &::after,
                    &::before {
                        background: $white-color;
                    }
                }
            }
        }
    }

    // &:hover {
    //     >a {
    //         @include breakpoint(lg) {
    //             &:before {
    //                 transform: translateY(-50%) rotate(90deg);
    //                 opacity: 0;
    //             }
    //         }
    //     }
    // }

    &.open {
        >a {

            &:before {
                transform: translateY(-50%) rotate(90deg);
                opacity: 0;
            }
        }
    }
}


// preloader
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    width: 100%;
    height: 100%;
    background: $theme-color;

    .preloader-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        .preloader-icon {
            width: 72px;
            height: 72px;
            display: inline-block;
            padding: 0px;

            span {
                position: absolute;
                display: inline-block;
                width: 72px;
                height: 72px;
                border-radius: 100%;
                background: $white-color;
                -webkit-animation: preloader-fx 1.6s linear infinite;
                -moz-animation: preloader-fx 1.6s linear infinite;
                animation: preloader-fx 1.6s linear infinite;

                &:last-child {
                    animation-delay: -0.8s;
                    -webkit-animation-delay: -0.8s;
                    -moz-animation-delay: -0.8s;
                }
            }
        }
    }
}

@keyframes preloader-fx {
    0% {
        transform: scale(0, 0);
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        opacity: 0.5;
    }

    100% {
        transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        opacity: 0;
    }
}

@-webkit-keyframes preloader-fx {
    0% {
        transform: scale(0, 0);
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        opacity: 0.5;
    }

    100% {
        transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        opacity: 0;
    }
}


// scrollTop
.scrollToTop {
    position: fixed;
    bottom: -30%;
    right: 5%;
    z-index: 99;

    i {
        @extend %d-inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        border-radius: 5px;
        @extend %text-center;
        color: $white-color;
        background: $theme-color;
        font-size: $fs-h4;
        @include add-prefix(transition, $transition);

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0px 5px 20px 0px rgba($theme-color, $alpha: .50);
        }
    }
}

//Common filter buttons

.filter-btns {
    @extend %mb-30,
    %justify-center;
    gap: 2px;

    .filter-btn {
        background-color: $primary-color !important;
        border-radius: 0 !important;
        padding: 1rem 2rem;
        color: $white-color;
        font-family: $title-font;
        text-transform: uppercase;

        &.active {
            background-color: $theme-color !important;
        }
    }
}