.game {


    &__item {

        background-color: $body-color;
        border-top: 2px solid $theme-color;
        display: block;

        &-top {
            @extend %rel;
            display: block;
        }

        &-thumb {
            @extend %hidden;

            img {
                @extend %w-100;
                @include add-prefix(transition, $transition);
            }
        }

        &-topTeam {
            @extend %d-inline-block,
            %abs;
            top: 0;
            left: 0;
            z-index: 1;
            background-color: $theme-color;
            color: $white-color;
            padding-block: .5rem;
            padding-inline: .75rem;
            clip-path: polygon(0 0, 100% 0, 95% 100%, 0% 100%);

            h5 {
                @extend %mb-0;

                @include breakpoint(max-md) {
                    @include font-size(16px);
                }
            }

            @include breakpoint(sm) {
                padding-block: .5rem;
                padding-inline-start: 2rem;
                padding-inline-end: 10%;
                clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
            }
        }

        &-topPrize {
            @extend .game__item-topTeam;
            left: auto;
            right: 0;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%);

            @include breakpoint(sm) {
                padding-inline-start: 10%;
                padding-inline-end: 2rem;
                clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
            }
        }

        &-title {
            @extend %text-center,
            %abs;
            @include add-prefix(transform, translateX(-50%));
            bottom: 20px;
            left: 50%;
            z-index: 1;
            width: max-content;
        }

        &-overlay {
            @extend %abs,
            %h-100,
            %w-100;
            top: 0;
            left: 0;
            background-image: -moz-linear-gradient(90deg, rgba($body-color, .8) 0%, rgba(9, 11, 52, 0) 100%);
            background-image: -webkit-linear-gradient(90deg, rgba($body-color, .8) 0%, rgba(9, 11, 52, 0) 100%);
            background-image: -ms-linear-gradient(90deg, rgba($body-color, .8) 0%, rgba(9, 11, 52, 0) 100%);
            background-image: -webkit-linear-gradient(90deg, rgba(5, 6, 29, 0.8) 0%, rgba(9, 11, 52, 0) 100%);


        }

        &:hover {
            .game__item-thumb {
                img {
                    @include add-prefix(transform, scale(1.1));
                }
            }
        }


        &--style-2 {

            .game__item {
                &-topTeam {
                    @extend %d-inline-block,
                    %abs;
                    top: initial;
                    bottom: 0 !important;
                    left: 0;
                    z-index: 1;
                    background-color: $theme-color;
                    color: $white-color;
                    padding-block: .5rem;
                    padding-inline: .75rem;
                    clip-path: none !important;

                    h5 {
                        @extend %mb-0;
                    }

                    @include breakpoint(sm) {
                        padding-block: .5rem;
                        padding-inline-start: 2rem;
                        padding-inline-end: 10%;
                        clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
                    }
                }

                &-topPrize {
                    @extend .game__item-topTeam;
                    left: auto;
                    right: 0;
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%);

                    @include breakpoint(sm) {
                        padding-inline-start: 10%;
                        padding-inline-end: 2rem;
                        clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
                    }
                }

                &-title {
                    @extend %abs;
                    transform: none;
                    top: 20px;
                    left: 20px;
                    z-index: 1;
                    text-align: left;

                }

                &-overlay {
                    background-image: none;
                }
            }

        }
    }

}